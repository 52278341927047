import React, { useEffect, useState } from "react"
import loadingImg from "../assets/loadingBars.svg"
import { graphql } from "gatsby"
import { ThemeProvider, CssBaseline, Grid } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { Timeline } from "react-twitter-widgets"
import { main } from "../theme"
import CustomFooter from "../components/CustomFooter"
import { useHomePosts } from "../operations/queries/homePosts"
import { HomePosts as HomePostsProps } from "../models/HomePosts"
import { Webinar as WebinarModel } from "../models/Webinar"
import "../theme/basics.css"
import useIsClient from "../utils/isClient"
import HeaderLayout from "../components/layout/HeaderLayout"
import ModalSuscribe from "../components/layout/ModalSuscribre"
import RecentPosts from "../components/layout/RecentPosts"
import { HeadApi } from "../components/HeadApi"
import parseDate from "../utils/parseDate"


const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginTop: "110px",
      marginBottom: "15px",
      fontSize: "45px",
      fontWeight: 900,
    },
    HeaderBasics: {
      width: "100%",
      "& .header--tags": {
        display: "flex",
        color: "yellow",
      },
    },
    HeaderAutor: {
      display: "flex",
      color: "yellow",
      alignItems: "center",
      marginBottom: "1em",
    },
    HeaderAutorInfo: {
      display: "flex",
      alignItems: "center",
      "& p": {
        marginBottom: "0",
        marginRight: "1rem",
        color: "#959390",
        fontWeight: "500",
      },
    },
    HeaderTags: {
      marginBottom: "35px",
      "& span": {
        marginRight: "3px",
        borderRadius: "12px",
        padding: "5px 12px",
        textTransform: "uppercase",
        fontSize: "10px",
        fontWeight: 500,
        lineHeight: "1",
        color: "#FFF",
        textAlign: "center",
        whiteSpace: "nowrap",
        verticalAlign: "baseline",
        display: "inline-block",
        backgroundColor: "#08355E",
      },
    },
    IntroBasics: {
      marginBottom: ".5rem",
      paddingRight: "1rem",
      "& .wrap_text": {
        "& div p": {
          marginBottom: ".5rem",
        },
      },
      "& .wrap_img": {
        padding: ".5rem 0",
        "& div": {
          width: "100%",
          height: "100%",
          overflow: "hidden",
          "& img": {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        },
      },
    },
    BodyBasics: {
      paddingRight: "1rem",
      "& p": {
        marginBottom: ".5rem",
      },
    },
    wrapInsta: {
      padding: "0.5rem",
      overflowY: "scroll",
      height: "420px",
      marginBottom: "1rem",
    },
    postInsta: {
      marginBottom: "1rem",
      "& .header_insta": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "54px",
        padding: "10px",
        background: "#fff",
        borderRadius: "8px 8px 0 0",
        boxShadow: [
          "0 1px 2px rgba(0,0,0,0.05)",
          "0 2px 4px rgba(0,0,0,0.05)",
          "0 4px 8px rgba(0,0,0,0.05)",
          "0 8px 16px rgba(0,0,0,0.05)",
          "0 16px 32px rgba(0,0,0,0.05)",
          "0 32px 64px rgba(0,0,0,0.05)",
        ],
      },
      "& .insta_video": {
        lineHeight: "0",
        "& video": {
          width: "100%",
          height: "100%",
        },
      },
    },
    container: {
      // marginTop: 70,
      paddingTop: "50px",
      paddingBottom: "50px",
      paddingRight: "20px",
      paddingLeft: "20px",
      marginRight: "auto",
      marginLeft: "auto",
      width: "100%",
      "@media (min-width: 576px)": {
        maxWidth: "540px",
      },
      "@media (min-width: 768px)": {
        maxWidth: "720px",
      },
      "@media (min-width: 992px)": {
        maxWidth: "960px",
      },
      "@media (min-width: 1200px)": {
        maxWidth: "1140px",
      },
    },
  }),
)

function useYouTubeID(url) {
  const [videoId, setVideoId] = useState("")

  useEffect(() => {
    const regExp = /^.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/
    const match = url.match(regExp)
    if (match && match[1].length === 11) {
      setVideoId(match[1])
    } else {
      console.error("Invalid YouTube URL")
      setVideoId("")
    }
  }, [url])

  return videoId
}


export const Head = ({ data }) => {
  const post = data.webinar_gph
  return (
    <HeadApi
      title={post.tituloDelWebinar}
      description={post.introduccionwebinar.introduccionwebinar}
      img={post.imagehead?.gatsbyImageData.images?.fallback == undefined ? post.imagehead?.gatsbyImageData.images.sources[0].srcSet.split(",").slice(-1)[0].split(" ")[0] : post.imagehead?.gatsbyImageData.images?.fallback.src}
    />
  )
}

const Webinar = ({ showFooter = true, data }) => {

  const dataRecent: HomePostsProps = useHomePosts()
  const post: WebinarModel = data.webinar_gph
  const videoId = useYouTubeID(post.youtubelink)
  const classes = useStyles()
  const [loading, setLoading] = useState(true)

  //functions modal suscribe
  const [open, setOpen] = useState(false)

  function handleLoad() {
    setLoading(false)
  }

  function openSuscribe() {
    setOpen(true)
  }

  function closeSuscribe() {
    setOpen(false)
  }

  const isClient = useIsClient()
  if (isClient) {
    return (
      <>
        <ThemeProvider theme={main}>
          <CssBaseline />
          <HeaderLayout ticker={false} openModal={openSuscribe} />

          <div className={classes.container}>
            <Grid container spacing={0}>

              <Grid item xs={12} md={8}>

                {/* Wrapper Header Basics */}
                <div className={classes.HeaderBasics}>
                  <div className={classes.HeaderAutor}>
                    {/* <Avatar className={classes.avatarPic} src={post.authorObject.avatar.gatsbyImageData?.images.fallback.src} alt=""/> */}
                    <div className={classes.HeaderAutorInfo}>
                      <p>Vector Análisis, <span>{parseDate(post.createdAt)}</span></p>
                    </div>
                  </div>
                </div>

                <div>
                  <h1 style={{ marginBottom: ".9rem" }}>{post.tituloDelWebinar}</h1>
                  <h2>{post.introduccionwebinar.introduccionwebinar}</h2>
                </div>

                <div className={classes.BodyBasics}>
                  {loading && (
                    <div style={{
                      position: "absolute",
                      width: "70%",
                      height: "80%",
                      backgroundColor: "white",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                      <div>
                        <img src={loadingImg} alt="Loading" />
                      </div>
                    </div>
                  )}
                  <iframe width="100%" height="695" src={`https://www.youtube.com/embed/${videoId}`}
                          onLoad={handleLoad}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>

              </Grid>
              <Grid item xs={12} md={4} style={{ paddingLeft: "1rem" }}>

                <div style={{ position: "relative", height: "80vh" }}>
                  <Timeline
                    dataSource={{
                      sourceType: "profile",
                      screenName: "vectoranalisis",
                    }}
                    options={{
                      height: "600",
                    }}
                  />
                </div>


              </Grid>
            </Grid>

          </div>
          <div className="container">
            <RecentPosts posts={dataRecent.recentArticles} title="Lo más reciente" link={false} />
          </div>
          {showFooter && <CustomFooter openModal={openSuscribe} />}
          {
            open && <ModalSuscribe closeModal={closeSuscribe} />
          }
        </ThemeProvider>
      </>
    )
  } else {
    return (<></>)
  }
}
export default Webinar

export const pageQuery = graphql`

  query($slug: String!) {
    webinar_gph: contentfulWebinar(slug: { eq: $slug }) {
      id
      tituloDelWebinar
      youtubelink
      slug
      introduccionwebinar {
      introduccionwebinar
      }
      createdAt
    }
  }
`
